import { gql } from '@apollo/client';
import { ReservationFragment } from 'graphql/reservations';

export const ThirdPartySignInMutation = gql`
  mutation thirdPartySignIn(
    $code: String
    $credential: String
    $provider: ThirdPartyProvider!
    $accommodationId: ID!
    $scope: String!
  ) {
    thirdPartySignIn(
      code: $code
      credential: $credential
      provider: $provider
      accommodationId: $accommodationId
      scope: $scope
    )
  }
`;

export const DeleteThirdPartyMutation = gql`
  mutation deleteAccommodationThirdParty(
    $provider: ThirdPartyProvider!
    $accommodationId: ID!
  ) {
    deleteAccommodationThirdParty(
      provider: $provider
      accommodationId: $accommodationId
    )
  }
`;

export const SmartAccessRoomsQuery = gql`
  query smartAccessRooms($accommodationId: ID!) {
    getSmartAccessRooms(accommodationId: $accommodationId) {
      roomName
      roomId
      roomPath
      devices {
        macId
        deviceId
        deviceName
        lockStatus
        batteryUsage
      }
    }
  }
`;

export const AccommodationThirdPartyAppsQuery = gql`
  query getAccommodationThirdPartyApps($accommodationId: ID!) {
    getAccommodationThirdPartyApps(accommodationId: $accommodationId) {
      provider
      scope
    }
  }
`;

export const registerThirdPartyPropertyMutation = gql`
  mutation registerThirdPartyProperty($input: ThirdPartyPropertyInput!) {
    registerThirdPartyProperty(input: $input) {
      id
      provider
      type
      thirdPartyId
      relatedId
      name
      description
      data
    }
  }
`;

export const getReservationGuestSessionTokenMutation = gql`
  mutation getReservationGuestSessionToken(
    $reservationId: ID!
    $pinCode: String!
  ) {
    getReservationGuestSessionToken(
      reservationId: $reservationId
      pinCode: $pinCode
    )
  }
`;

export const getSingleReservationByGuestSessionQuery = gql`
  query getSingleReservationByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
  ) {
    getSingleReservationByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
    ) {
      ...ReservationFragment
      accommodationId
      data
      phone
      roomType {
        thumbnail
        currentPrice {
          id
          addonAmount
          priceType
          extraGuestAmount
          lateCheckoutLimit
          lateCheckoutMaximum
          priceTimes {
            type
            startTime
            endTime
          }
        }
      }
      room {
        thumbnail
        checkIn
        checkOut
        state
        order
        description
        defaultSleeps
        maxSleeps
        amenities {
          name
          icon
        }
        dayCheckOut
        themes {
          id
          name
        }
      }
      payment {
        amount
        totalAmount
      }
      roomType {
        thumbnail
      }
    }
  }

  ${ReservationFragment}
`;
export const getReservationDatePrices = gql`
  query getSingleReservationByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    getSingleReservationByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
    ) {
      roomType {
        datePrices(startDate: $startDate, endDate: $endDate) {
          date
          price {
            id
            defaultRentTime
            extendedRentTime
            rentExpireAt
            priceType
            cardAmount
            cashAmount
            priceTimes {
              type
              startTime
              endTime
            }
            extraCleanAmount
            lateCheckoutLimit
            lateCheckoutMaximum
            addonSleepsMaximum
            addonAmount
            addonAmount2
            extraGuestAmount
            useAddon
            isEnabled
            priority
            pricePeriodId
            roomTypeId
          }
        }
      }
    }
  }
`;

export const checkInReservationByGuestSessionMutation = gql`
  mutation checkInReservationByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
  ) {
    checkInReservationByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
    ) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

export const checkOutReservationByGuestSessionMutation = gql`
  mutation checkOutReservationByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
  ) {
    checkOutReservationByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
    ) {
      ...ReservationFragment
    }
  }
  ${ReservationFragment}
`;

export const controlRoomDeviceByGuestSessionMutation = gql`
  mutation controlRoomDeviceByGuestSession(
    $reservationId: ID!
    $sessionToken: String!
    $deviceType: String!
    $data: String
  ) {
    controlRoomDeviceByGuestSession(
      reservationId: $reservationId
      sessionToken: $sessionToken
      deviceType: $deviceType
      data: $data
    )
  }
`;
